import { template as template_cbf30828244344b7bd275639e6517c97 } from "@ember/template-compiler";
const FKText = template_cbf30828244344b7bd275639e6517c97(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
