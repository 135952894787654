import { template as template_8c18c21ce9914d008f41fb8d845576a9 } from "@ember/template-compiler";
const SidebarSectionMessage = template_8c18c21ce9914d008f41fb8d845576a9(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
