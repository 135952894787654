import { template as template_a0bf5aef96af44fe9f6735b04cdd35ab } from "@ember/template-compiler";
const FKLabel = template_a0bf5aef96af44fe9f6735b04cdd35ab(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
