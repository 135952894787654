import { template as template_62f4ca596dbd4eeb8574bdf375d16520 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_62f4ca596dbd4eeb8574bdf375d16520(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
